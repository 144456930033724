<template>
  <div class="refferal-details">
    <div class="refferal-details__total">
      <span><b>$</b>{{ totalBonus.toFixed(0) }}</span>
    </div>
    <div class="refferal-details__users">
      <div class="refferal-details__item">
        <div class="refferal-details__text">{{ $t("refferalDownloads") }}</div>
        <div class="refferal-details__count">
          <b>{{ activateCount }}</b>
        </div>
        <img
          class="refferal-details__img"
          src="@/assets/img/Choosing/Friend.svg"
        />
      </div>
      <div class="refferal-details__item">
        <div class="refferal-details__text">{{ $t("refferalActivates") }}</div>
        <div class="refferal-details__count">
          <b>{{ orderCount }}</b>
        </div>
        <img class="refferal-details__img" src="@/assets/img/Money.svg" />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "RefferalDetails",
  props: {
    totalBonus: {
      type: Number,
      default: 0,
    },
    activateCount: {
      type: Number,
      default: 0,
    },
    orderCount: {
      type: Number,
      default: 0,
    },
  },
});
</script>

<style>
.refferal-details__total {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 10px;
  position: relative;
  width: 100px;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -50px;
  border-radius: 50%;
  background: #ffffff;
  padding: 14px;
  box-sizing: border-box;
}
.refferal-details__total:after {
  display: block;
  content: "";
  width: calc(100% - 28px);
  height: calc(100% - 28px);
  position: absolute;
  z-index: 1;
  border-radius: 50%;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.2);
}
.refferal-details__text {
  font-size: 12px;
  color: #696969;
  text-align: left;
}
.refferal-details__users {
  padding-bottom: 16px;
  display: flex;
  align-items: center;
  margin-left: -10px;
  margin-right: -10px;
  margin-top: -10px;
}
.refferal-details__item {
  position: relative;
  background: #f8f8f8;
  padding: 12px;
  border-radius: 8px;
  margin-left: 10px;
  margin-right: 10px;
  width: 50%;
}
.refferal-details__img {
  position: absolute;
  display: block;
  right: 18px;
  top: 0;
  bottom: 0;
  margin: auto;
}
.refferal-details__count b {
  font-size: 16px;
  color: #282828;
  font-family: "Helvetica Neue Medium";
  line-height: 1;
}
.refferal-details__total span {
  color: #ffffff;
  font-family: "Helvetica Neue Medium";
  font-size: 24px;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding-top: 4px;
  position: relative;
  z-index: 2;
  background: #91c258;
  -webkit-box-shadow: 0 0 0 0 #91c258;
  box-shadow: 0 0 0 0 #91c258;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-animation: pulse 2s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulse 2s infinite cubic-bezier(0.66, 0, 0, 1);
}
.refferal-details__total span b {
  font-size: 14px;
  position: relative;
  top: -6px;
  left: -6px;
  margin-right: -6px;
  color: #c8e1ac;
  font-family: "Helvetica Neue Medium";
}

@-webkit-keyframes pulse {
  to {
    -webkit-box-shadow: 0 0 0 15px rgba(232, 76, 61, 0);
    box-shadow: 0 0 0 15px rgba(232, 76, 61, 0);
  }
}
@keyframes pulse {
  to {
    -webkit-box-shadow: 0 0 0 15px rgba(232, 76, 61, 0);
    box-shadow: 0 0 0 15px rgba(232, 76, 61, 0);
  }
}
</style>
