





























































import Vue from "vue";
import { Prop } from "vue/types/options";
import { mapGetters } from "vuex";

import RefferalDetails from "./RefferalDetails.vue";
import RefferalShare from "./RefferalShare.vue";

export default Vue.extend({
  name: "RefferalPage",
  components: { RefferalDetails, RefferalShare },
  props: {
    routeparam: Object as Prop<any>,
  },
  data() {
    return {
      isInfoOpened: false,
    };
  },
  computed: {
    ...mapGetters("user", ["user", "refferalLink"]),
    ...mapGetters("root", ["refferalRate"]),
  },
  methods: {},
});
