<template>
  <div class="refferal-share">
    <div class="refferal-share__copy">
      <div class="refferal-share__qrcode" @click="showQR = true">
        <img src="@/assets/img/qr-code.svg" />
      </div>
      <span @click="onCopy">{{ shareLink }}</span>
    </div>
    <div class="refferal-share__list">
      <!-- <div class="refferal-share__item" @click="onShare('tg')">
        <img src="@/assets/img/social/tg.svg" />
      </div>
      <div class="refferal-share__item" @click="onShare('fb')">
        <img src="@/assets/img/social/fb.png" />
      </div>
      <div class="refferal-share__item" @click="onShare('viber')">
        <img src="@/assets/img/social/vb.svg" />
      </div>
      <div class="refferal-share__item" @click="onShare('mail')">
        <img src="@/assets/img/social/mail.svg" />
      </div>
      <div class="refferal-share__item" @click="onShare">
        <img src="@/assets/img/social/more.svg" />
      </div> -->
      <div @click="onShare" class="btnWideRound">{{ $t("btnShare") }}</div>
    </div>

    <transition key="qr-popup" name="fade">
      <div v-if="showQR" class="easy-push-popup">
        <div class="easy-push-popup__overlay" @click="onClose"></div>
        <div class="easy-push-popup__wrapper">
          <div class="easy-push-popup__content">
            <div class="easy-push-popup__close" @click="onClose">
              <img src="@/assets/img/close-w.svg" />
            </div>
            <div class="qr-popup__img">
              <qrcode
                :value="shareLink"
                :options="{ width: 200 }"
                tag="img"
              ></qrcode>
            </div>
            <div class="qr-popup__text">
              {{ $t("refferalShareQR") }}
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Vue from "vue";
import eventsRegister from "@/services/events-register";
import { copyToClipboard, logEventFA } from "@/utils/utils";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "RefferalShare",
  props: {
    shareLink: {
      type: String,
      default: "",
    },
    customer: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      shareOptions: {
        title: this.$t("refferalShareTitle"),
        img: "https://i.ibb.co/9Vv3sXT/Catcat.png",
      },
      showQR: false,
    };
  },
  computed: {
    ...mapGetters("root", ["refferalRate"]),
    shareMsg() {
      return `${this.$t("refferalShareText1")} ${
        this.shareLink
      } ${this.$t("refferalShareText2", { val: this.refferalRate })}`;
    },
  },
  methods: {
    onShare(type) {
      const sharePlugin = window.plugins && window.plugins.socialsharing;
      if (!sharePlugin) {
        this.onCopy();
        return false;
      }

      const onSuccess = () => {
        // this.$root.$emit(eventsRegister.addMessage, {
        //   type: "success",
        //   message: lcl.refferalShareSuccess,
        //   autoClose: true,
        // });
        this.logEvents();
      };

      const options = {
        message: this.shareMsg,
        chooserTitle: this.shareOptions.title,
        // files: [this.shareOptions.img],
      };

      switch (type) {
        case "fb":
          sharePlugin.shareViaFacebookWithPasteMessageHint(
            "Easyget - экспресс доставка",
            null /* img */,
            this.shareLink /* url */,
            "Paste it dude!",
            () => {
              console.log("share ok");
            }
          );
          break;
        case "mail":
          sharePlugin.shareViaEmail(
            this.shareLink, // can contain HTML tags, but support on Android is rather limited:  http://stackoverflow.com/questions/15136480/how-to-send-html-content-with-image-through-android-default-email-client
            "Easyget - экспресс доставка",
            null, // TO: must be null or an array
            null, // CC: must be null or an array
            null, // BCC: must be null or an array
            null, // FILES: can be null, a string, or an array
            () => {
              console.log("share ok");
            }
          );
          break;
        case "viber":
          sharePlugin.shareVia(
            "viber",
            "Easyget - экспресс доставка",
            null,
            null,
            null,
            () => {
              console.log("share ok");
            }
          );
          break;
        case "tg":
          sharePlugin.shareVia(
            "tg",
            "Easyget - экспресс доставка",
            null,
            null,
            null,
            () => {
              console.log("share ok");
            }
          );
          break;
        default:
          sharePlugin.shareWithOptions(options, onSuccess);
          break;
      }
    },
    onCopy() {
      const clipBoard =
        window["cordova"] && window["cordova"].plugins.clipboard;

      const onSuccess = () => {
        this.$root.$emit(eventsRegister.addMessage, {
          type: "success",
          message: this.$t("copied"),
          autoClose: true,
        });
        this.logEvents();
      };
      if (clipBoard) {
        window["cordova"].plugins.clipboard.copy(this.shareMsg, onSuccess);
      } else {
        copyToClipboard(this.shareMsg, onSuccess);
      }
    },

    onClose() {
      this.showQR = false;
    },

    logEvents() {
      logEventFA("share_invite_link", {
        customer_id: this.customer.ID,
      });
    },
  },
});
</script>

<style>
.refferal-share {
  padding-bottom: 24px;
  padding-top: 16px;
  border-top: 1px solid #f1f1f1;
}
.refferal-share__copy {
  background: rgb(145, 194, 88, 0.12);
  border-radius: 8px;
  height: 56px;
  display: flex;
  align-items: center;
  padding-right: 20px;
  position: relative;
}
.refferal-share__copy span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  color: #91c258;
  font-size: 16px;
  font-weight: bold;
  flex: 1 auto;
  height: 56px;
  line-height: 56px;
  padding-left: 16px;
}
.refferal-share__qrcode {
  height: 56px;
  width: 56px;
  display: flex;
  align-self: center;
  justify-content: center;
  border-right: 1px solid rgba(200, 225, 172, 0.3);
}
.refferal-share__qrcode img {
  max-width: 24px;
}
.refferal-share__list {
  display: flex;
  align-self: center;
  justify-content: space-between;
  margin-top: 24px;
}
.refferal-share__item {
  margin: 5px;
}
.refferal-share__item img {
  max-width: 46px;
}
.qr-popup__text {
  color: #696969;
  font-size: 14px;
  line-height: 1.33;
  margin-top: 12px;
  text-align: center;
  max-width: 314px;
  margin-left: auto;
  margin-right: auto;
}
.qr-popup__img {
  display: flex;
  justify-content: center;
}
</style>
